.footer {
  width: 100%;
  background: #aec1ba;
  font-family: var(--font-raleway);
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: #F2F2F2;
  padding: 20px 0;
  grid-area: footer;
  width: 100%;
  box-sizing: border-box;
}

.menu {
  display: flex;
  list-style: none;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding: 0;
  max-width: 600px;
}

.menu a {
  font-family: var(--font-raleway);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #464646;
  border-bottom: 2px solid transparent;
  display: block;
}

.menu a:visited,
.logout:visited {
  color: #464646;
}

.menu a:hover {
  text-decoration: none;
  border-bottom: 2px solid #F2A950;
}

.menu a.active {
  text-decoration: none;
  border-bottom: 2px solid #F2865E;
  color: #0D0D0D;
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 20px 0;
  }
}
