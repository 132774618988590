.main {
  font-size: 16px;
  line-height: 24px;
  max-width: 750px;
  margin: 34px auto;
  padding: 30px 20px;
  z-index: 2;
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  color: #0D0D0D;
  min-width: 300px;
}

.sectionChildren {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
.sectionChildren h2 {
  margin: 0;
}

.main label {
  display: block;
  font-size: 12px;
  color: #f2865e;
  text-transform: uppercase;
}

.main h1 {
  margin-bottom: 20px;
}

.input{
  border-width: 0 0 1px 0;
  border-color: #d2d2d2;
  background-color: transparent;
  margin: 0 0 20px 0;
  width: 100%;
}

.input:focus {
  outline: none;
  border-color: #f2865e;
}

.addChild {
  background-color: #49c817;
}

.children {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
}

.name {
  margin: 0 0 60px;
}

.logout {
  margin-top: 30px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .main {
    width: 100%;
  }
  .name {
    max-width: 60%;
  }
  .sectionChildren {
    margin-bottom: 60px;
  }
  .children {
    flex-direction: row;
  }
}
