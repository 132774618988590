.footer_footer__MbdlQ {
  width: 100%;
  background: #aec1ba;
  font-family: var(--font-raleway);
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: #F2F2F2;
  padding: 20px 0;
  grid-area: footer;
  width: 100%;
  box-sizing: border-box;
}

.footer_menu__06ozd {
  display: flex;
  list-style: none;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding: 0;
  max-width: 600px;
}

.footer_menu__06ozd a {
  font-family: var(--font-raleway);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #464646;
  border-bottom: 2px solid transparent;
  display: block;
}

.footer_menu__06ozd a:visited,
.footer_logout__ZXk7r:visited {
  color: #464646;
}

.footer_menu__06ozd a:hover {
  text-decoration: none;
  border-bottom: 2px solid #F2A950;
}

.footer_menu__06ozd a.footer_active__KbZ_S {
  text-decoration: none;
  border-bottom: 2px solid #F2865E;
  color: #0D0D0D;
}

@media only screen and (min-width: 768px) {
  .footer_footer__MbdlQ {
    padding: 20px 0;
  }
}

.header_header__dGL52 {
  max-width: 1366px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  grid-area: header;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
  position: relative;
}
.header_icon__BP6YQ {
  display: block;
  width: 73px;
  height: auto;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .header_header__dGL52 {
    padding: 27px 20px;
  }
  .header_icon__BP6YQ {
    width: 133px;
  }
}

@media screen and (min-width: 1024px) {
  .header_header__dGL52 {
    padding-left: calc(50% - 400px);
    padding-right: calc(50% - 400px);
  }
}

@media screen and (min-width: 1366px) {
  .header_header__dGL52 {
    width: 1366px;
    padding-left: 140px;
    padding-right: 140px;
  }
}

.navigation_navigation__JLOds {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.navigation_menu__vz5A_ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.navigation_menu__vz5A_ a {
  font-family: var(--font-raleway);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #464646;
  border-bottom: 2px solid transparent;
  display: block;
  margin: 10px;
}

.navigation_menu__vz5A_ a:visited,
.navigation_logout__YZdy0:visited {
  color: #464646;
}

.navigation_menu__vz5A_ a:hover {
  text-decoration: none;
  border-bottom: 2px solid #F2A950;
}

.navigation_menu__vz5A_ a.navigation_active__GrY7c {
  text-decoration: none;
  border-bottom: 2px solid #F2865E;
  color: #0D0D0D;
}

.navigation_avatar__tMc0E {
  width: 36px;
  height: 36px;
  margin: 0;
  display: block;
  border: 2px solid transparent;
  border-radius: 40px;
}

.navigation_avatar__tMc0E:hover,
.navigation_activeAvatar__gcQPq {
  border-color: #F2865E;
}

.navigation_logout__YZdy0,
.navigation_logout__YZdy0:hover {
  background: #edebdf;
  border-radius: 20px;
  padding: 0px;
  border-bottom: 0 transparent;
}

.navigation_menu__vz5A_ .navigation_logout__YZdy0:hover svg path {
  fill: #F2865E;
}

.navigation_avatar__tMc0E img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
}

@media screen and (min-width: 768px) {
  .navigation_menu__vz5A_ a {
    margin: 0 23px;
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/200388358b398524-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/34900c74a84112b6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/10dadb2e82d03733-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/5f2068c3133468f5-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/9bf67a161a796382-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Raleway_Fallback_904c5e';src: local("Arial");ascent-override: 90.51%;descent-override: 22.53%;line-gap-override: 0.00%;size-adjust: 103.86%
}.__className_904c5e {font-family: '__Raleway_904c5e', '__Raleway_Fallback_904c5e';font-style: normal
}.__variable_904c5e {--font-raleway: '__Raleway_904c5e', '__Raleway_Fallback_904c5e'
}

.profile_main__yM_3i {
  font-size: 16px;
  line-height: 24px;
  max-width: 750px;
  margin: 34px auto;
  padding: 30px 20px;
  z-index: 2;
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  color: #0D0D0D;
  min-width: 300px;
}

.profile_sectionChildren__9L1Dx {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
.profile_sectionChildren__9L1Dx h2 {
  margin: 0;
}

.profile_main__yM_3i label {
  display: block;
  font-size: 12px;
  color: #f2865e;
  text-transform: uppercase;
}

.profile_main__yM_3i h1 {
  margin-bottom: 20px;
}

.profile_input__DiX6P{
  border-width: 0 0 1px 0;
  border-color: #d2d2d2;
  background-color: transparent;
  margin: 0 0 20px 0;
  width: 100%;
}

.profile_input__DiX6P:focus {
  outline: none;
  border-color: #f2865e;
}

.profile_addChild__QJgU3 {
  background-color: #49c817;
}

.profile_children__VWNTR {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
}

.profile_name__tb9Rx {
  margin: 0 0 60px;
}

.profile_logout__qmwxw {
  margin-top: 30px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .profile_main__yM_3i {
    width: 100%;
  }
  .profile_name__tb9Rx {
    max-width: 60%;
  }
  .profile_sectionChildren__9L1Dx {
    margin-bottom: 60px;
  }
  .profile_children__VWNTR {
    flex-direction: row;
  }
}

.child_child__hek__ {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  box-shadow: #0000001c 4px 3px 6px 3px;
  padding: 30px 25px;
  box-sizing: border-box;
}

.child_buttons__m1Aml {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.child_removeChild__2iUcz {
  color: #e22121;
  font-size: smaller;
  text-decoration: none;
  cursor: pointer;
}

.child_input__sOk1O {
  border-width: 0 0 1px 0;
  border-color: #d2d2d2;
  background-color: transparent;
  margin: 0 0 20px 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .child_child__hek__ {
    width: 30%;
    margin-bottom: 60px;
  }
}

.subscribe_subscribe__6_0Ch {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
}

.subscribe_email__5LZf1 {
  border: 0;
  outline: 0;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 0;
  bottom: 2px;
  border-radius: 3px 0px 0px 3px;
  background: #f2f2f2;
  padding: 10px;
}

.subscribe_gradientWrapper__rGUbn {
  position: relative;
  height: 48px;
  border-radius: 4px 0px 0px 4px;
  background-color: #fff;
  width: 80%;
  background: #ededed;
}

.subscribe_gradient2__jYfsz {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px 0px 0px 4px;
  background: conic-gradient(from 77deg, rgba(140, 42, 134, 0) 0%, #8c2a86 50%, rgba(140, 42, 134, 0) 100%);
}

.subscribe_gradient1__a8khB {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px 0px 0px 4px;
  background: conic-gradient(from 261deg, #737f77 0%, rgba(115, 127, 119, 0) 100%);
}

.subscribe_input__b31Vz {
  border-radius: 0px 3px 3px 0px;
}

.subscribe_email__5LZf1:focus {
  box-shadow: 0px 0 6px rgba(0, 0, 0, 0.75);
}

.subscribe_email__5LZf1::placeholder {
  color: #cfd0cb;
  font-size: 13px;
  line-height: 19px;
}

.subscribe_errorContainer__CVGPD {
  display: none;
}
.subscribe_error__gqIMD .subscribe_errorContainer__CVGPD {
  display: block;
  color: rgb(200, 20, 20);
}


@media screen and (min-width: 768px) {
  .subscribe_subscribe__6_0Ch {
    max-width: 528px;
    margin: 0 auto;
  }
}

