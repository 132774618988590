.subscribe {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
}

.email {
  border: 0;
  outline: 0;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 0;
  bottom: 2px;
  border-radius: 3px 0px 0px 3px;
  background: #f2f2f2;
  padding: 10px;
}

.gradientWrapper {
  position: relative;
  height: 48px;
  border-radius: 4px 0px 0px 4px;
  background-color: #fff;
  width: 80%;
  background: #ededed;
}

.gradient2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px 0px 0px 4px;
  background: conic-gradient(from 77deg, rgba(140, 42, 134, 0) 0%, #8c2a86 50%, rgba(140, 42, 134, 0) 100%);
}

.gradient1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px 0px 0px 4px;
  background: conic-gradient(from 261deg, #737f77 0%, rgba(115, 127, 119, 0) 100%);
}

.input {
  border-radius: 0px 3px 3px 0px;
}

.email:focus {
  box-shadow: 0px 0 6px rgba(0, 0, 0, 0.75);
}

.email::placeholder {
  color: #cfd0cb;
  font-size: 13px;
  line-height: 19px;
}

.errorContainer {
  display: none;
}
.error .errorContainer {
  display: block;
  color: rgb(200, 20, 20);
}


@media screen and (min-width: 768px) {
  .subscribe {
    max-width: 528px;
    margin: 0 auto;
  }
}
