.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.menu a {
  font-family: var(--font-raleway);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #464646;
  border-bottom: 2px solid transparent;
  display: block;
  margin: 10px;
}

.menu a:visited,
.logout:visited {
  color: #464646;
}

.menu a:hover {
  text-decoration: none;
  border-bottom: 2px solid #F2A950;
}

.menu a.active {
  text-decoration: none;
  border-bottom: 2px solid #F2865E;
  color: #0D0D0D;
}

.avatar {
  width: 36px;
  height: 36px;
  margin: 0;
  display: block;
  border: 2px solid transparent;
  border-radius: 40px;
}

.avatar:hover,
.activeAvatar {
  border-color: #F2865E;
}

.logout,
.logout:hover {
  background: #edebdf;
  border-radius: 20px;
  padding: 0px;
  border-bottom: 0 transparent;
}

.menu .logout:hover svg path {
  fill: #F2865E;
}

.avatar img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
}

@media screen and (min-width: 768px) {
  .menu a {
    margin: 0 23px;
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
  }
}
