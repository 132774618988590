.child {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  box-shadow: #0000001c 4px 3px 6px 3px;
  padding: 30px 25px;
  box-sizing: border-box;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.removeChild {
  color: #e22121;
  font-size: smaller;
  text-decoration: none;
  cursor: pointer;
}

.input {
  border-width: 0 0 1px 0;
  border-color: #d2d2d2;
  background-color: transparent;
  margin: 0 0 20px 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .child {
    width: 30%;
    margin-bottom: 60px;
  }
}
